//mess.js
export default {
  data() {
    return {
      leftName: process.env.NODE_ENV === 'prod' ? 'ICP:京ICP备2023008308号-6' : 'ICP证:京ICP备2023008308号-2',
      rightName: process.env.NODE_ENV === 'prod' ? '渝公网安备50011202504345号' : '渝公网安备50011202503902号',
      name: process.env.NODE_ENV === 'prod' ? 'ixybz' : 'bubuluck',
      recordcode: process.env.NODE_ENV === 'prod' ? '50011202504345' : '50011202503902',
    };
  },
  methods: {
    goHome(v, obj) {
      //首页
      if (v === 1) {
        this.$router.push('/')
        window.scrollTo(0, 0);
        //h5-------卜卜官网
      } else if (v === 2) {
        window.location.href = 'https://www.bububz.com/'
        //h5-------下载中心 移动端
      } else if (v === 3) {
        this.$router.push('/mobile_download')
        window.scrollTo(0, 0);
        //h5-------新用户服务协议地址
      } else if (v === 4) {
        window.location.href = 'https://www.bububz.com/xy/userAgreement.html'
        //h5-------新隐私保护政策地址
      } else if (v === 5) {
        window.location.href = 'https://www.bububz.com/xy/privacy.html'

      } else if (v === 11) {
        window.location.href = 'https://www.bububz.com/download/'

      } else if (v === 8) {
        //h5-------下载中心
        this.$router.push('/pop')
      } else if (v === 6) {
        //---------------------------------------H5
        //pc-------下载中心
        this.$router.push('/download')

      } else if (v === 7) {
        //---------------------------------------第三方地址
        // bubu官网
        window.location.href = 'https://www.bububz.com/'

      } else if (v === 9) {
        //ICP:京ICP备
        window.location.href = 'https://beian.miit.gov.cn'
      } else if (v === 10) {
        //渝公网安备50011202503819号
        window.location.href = 'https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=' + obj.recordcode
      } else if (v === 'back') {
        this.$router.back()
        window.scrollTo(0, 0);
      }

    }
  },
  mounted() {
    console.log(this.message)
  }
};
