<template>
  <div style="width:100%">
    <!--    移动端导航页面-->
    <div style="width: 100%;height: 2.88rem;background-color: white;position: relative">
      <div
        style="position: absolute;top:0.768rem;left: 0.64rem;font-family: PingFang SC, PingFang SC;font-weight: bold;font-size: 0.96rem;color: #040407;">
        玄易无限
      </div>
      <img :src="menu" alt="" style="width: 1.2672rem;position: absolute;top:0.8rem;right: 0.64rem"
           @click="goHome('back')">
    </div>
    <div style="width: 100%">
      <div style="width: 100%;display: flex;justify-content: center;align-items: center;margin-top: 1.504rem">
        <img :src="close" style="width: 0.832rem" @click="goHome('back')">
      </div>
      <div
        style="font-family: PingFang SC, PingFang SC;font-weight: 400;font-size: 1.152rem;color: #040407;text-decoration-line: underline;text-align: center;margin-top: 0.992rem"
        @click="goHome(1)">
        首页推荐
      </div>
      <div
        style="font-family: PingFang SC, PingFang SC;font-weight: 300;font-size: 0.64rem;color: #000000;;text-align: center;margin-top: 0.192rem">
        Home
      </div>
      <div
        style="font-family: PingFang SC, PingFang SC;font-weight: 400;font-size: 1.152rem;color: #040407;text-decoration-line: underline;;text-align: center;margin-top: 1.28rem"
        @click="goHome(2)">
        卜卜排盘
      </div>
      <div
        style="font-family: PingFang SC, PingFang SC;font-weight: 300;font-size: 0.64rem;color: #000000;;text-align: center;margin-top: 0.192rem">
        Bubu
        platter
      </div>
      <div
        style="font-family: PingFang SC, PingFang SC;font-weight: 400;font-size: 1.152rem;color: #040407;text-decoration-line: underline;;text-align: center;margin-top: 1.28rem"
        @click="goHome(3)">
        下载中心
      </div>
      <div
        style="font-family: PingFang SC, PingFang SC;font-weight: 300;font-size: 0.64rem;color: #000000;;text-align: center;margin-top: 0.192rem"
      >
        Download
      </div>
    </div>
  </div>
</template>
<script>
import mixin from '@/mixins/comment'

export default {
  mixins: [mixin],
  name: 'popMobile.vue',
  components: {},
  data() {
    return {
      mobile_bg1: require('@/assets/div_2/mobile_bg1.png'),
      menu: require('@/assets/div_2/menu.png'),
      close: require('@/assets/div_2/close.png'),
    }
  },
  methods: {},
  created() {
  }
}
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}

img {
  display: block;
  height: auto;
}
</style>
