<template>
  <div>
    <!--    PC端导航栏-->
    <div v-if="!isMobile" class="div_a">
      <!-- <img :src="navigationBar" style="width: 100%"> -->
      <!-- <div class="div_a_a">
        <img :src="menuUrl_1"
             style="width: 1.08rem;cursor:pointer" @click="goHome(1)">
        <img :src="menuUrl_2"
             style="width: 1.08rem;cursor:pointer;margin-left: 0.228rem" @click="goHome(7)">
        <img :src="menuUrl_3"
             style="width: 1.08rem;cursor:pointer;margin-left: 0.228rem" @click="goHome(6)">
        <img :src="vertical" style="width:0.240rem;margin-left: 0.228rem">
      </div> -->
    </div>
    <!--    移动端导航栏-->
    <!--    <div v-if="isMobile" class="div_b">-->
    <!--      <div class="div_b_a">-->
    <!--        <img v-show="!isHome()" :src="back" alt="" class="div_b_a_a"-->

    <!--             @click="clickBack()">-->
    <!--        <div class="div_b_a_b"-->
    <!--        >-->
    <!--          玄易排盘-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <router-view></router-view>
    <div></div>
  </div>
</template>

<script>

import mixin from '@/mixins/comment'

export default {
  mixins: [mixin],
  name: 'App',
  components: {},
  data() {
    return {
      navigationBar: require('@/assets/div_1/navigationBar.png'),
      menuUrl_1: require('@/assets/div_1/menu_1.png'),
      menuUrl_2: require('@/assets/div_1/menu_2.png'),
      menuUrl_3: require('@/assets/div_1/menu_3.png'),
      vertical: require('@/assets/div_1/vertical.png'),
      isMobile: false,
      back: require('@/assets/div_2/back.png'),
    }
  },
  methods: {
    isHome() {
      console.log('this.$route.name === \'首页\'', this.$route.name === '首页')
      return this.$route.name === '首页'
    },
    to() {
      this.$router.push({
        path: '/statistic/expiration_detail?item='
      })
    }
  },
  created() {
    // document.title = '玄易排盘';
    this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }
}
</script>

<style scoped>


.div_a {
  position: relative;
  font-size: 0;

  .div_a_a {
    width: 30%;
    height: 100%;
    padding-left: 3.7rem;
    position: absolute;
    top: 0.416rem;
    left: 50%;

    .div_a_a_a {
      display: inline-block;
      cursor: pointer;
      width: 33%
    }

    .div_a_a_b {
      width: 100px;
    }
  }
}

.div_b {
  position: relative;

  .div_b_a {
    width: 100%;
    height: 2.816rem;
    background-color: white;
    position: relative;

    .div_b_a_a {
      width: 0.57696rem;
      position: absolute;
      top: 0.896rem;
      left: 0.64rem
    }

    .div_b_a_b {
      font-family: PingFang SC, PingFang SC;
      font-weight: bold;
      font-size: 34px;
      color: black;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%)
    }
  }
}
</style>
