<template>
  <div style="width:100%">
    <div style="width: 100%">
      <div style="position: relative">
        <img :src="mobile_bg9" alt="" style="width: 100%">
        <div style="width: 100%;position: absolute;top: 5.872rem;left:0rem">
          <!-- <div
            style="font-family: PingFang SC, PingFang SC;font-weight: 400;font-size: 0.832rem;color: #EFE8BA;text-align: center">
            <span @click="goHome(1)">首页推荐</span> | <span @click="goHome(2)">卜卜排盘</span> | <span
            @click="goHome(3)">下载中心</span>
          </div> -->
          <div
            style="margin-top: 1.888rem;font-family: PingFang SC, PingFang SC;font-weight: 400;font-size: 0.64rem;color: #ACACAC;text-align: center">
            版权所有
            北京玄易无限网络科技有限公司
          </div>
          <div
            style="margin-top: 0.448rem;font-family: PingFang SC, PingFang SC;font-weight: 400;font-size: 0.64rem;color: #ACACAC;text-align: center">
            地址：北京市门头沟区石龙经济开发区平安路5号4幢DY2062
          </div>
          <div
            style="margin-top: 0.448rem;font-family: PingFang SC, PingFang SC;font-weight: 400;font-size: 0.64rem;color: #ACACAC;text-align: center">
            邮箱：help@{{ name }}.com
          </div>
          <div
            style="margin-top: 0.448rem;font-family: PingFang SC, PingFang SC;font-weight: 400;font-size: 0.64rem;color: #ACACAC;text-align: center">
            © Copyright by 2023-{{ endTime }} bububz.com
          </div>
          <div
            style="margin-top: 1.976rem;font-family: DengXian, DengXian;font-weight: 400;font-size: 0.672rem;color: #EFE8BA;text-align: center">
            <span style="text-decoration-line: underline;" @click="goHome(4)">用户服务协议</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span style="text-decoration-line: underline;" @click="goHome(5)">隐私保护政策</span>
          </div>
          <div style="width: 100%;display: flex;justify-content: center;align-items: center;margin-top: 2.200rem">
            <span
              style="font-family: PingFang SC, PingFang SC;font-weight: 400;font-size: 0.64rem;color: #ACACAC;cursor:pointer">
              <span @click="goHome(9)">{{ leftName }}</span>
            </span>
            <img :src="icon_2" style="margin: 0px 10px"
                 width="20px">
            <span
              style="font-family: PingFang SC, PingFang SC;font-weight: 400;font-size: 0.64rem;color: #ACACAC;cursor:pointer">
              <span @click="goHome(10,{recordcode:recordcode})">{{ rightName }}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mixin from '@/mixins/comment'
import moment from "moment/moment";

export default {
  mixins: [mixin],
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Bottom',
  components: {},
  data() {
    return {
      endTime: '',
      mobile_bg1: require('@/assets/div_2/mobile_bg1.png'),
      mobile_bg2: require('@/assets/div_2/mobile_bg2.png'),
      mobile_bg3: require('@/assets/div_2/mobile_bg3.png'),
      mobile_bg4: require('@/assets/div_2/mobile_bg4.png'),
      mobile_bg5: require('@/assets/div_2/mobile_bg5.png'),
      mobile_bg6: require('@/assets/div_2/mobile_bg6.png'),
      mobile_bg7: require('@/assets/div_2/mobile_bg7.png'),
      mobile_bg8: require('@/assets/div_2/mobile_bg8.png'),
      mobile_bg9: require('@/assets/div_2/mobile_bg9.png'),
      mobile_xiazai_button: require('@/assets/div_2/mobile_xiazai_button.png'),
      text_1: require('@/assets/div_2/text_1.png'),
      text_2: require('@/assets/div_2/text_2.png'),
      text_3: require('@/assets/div_2/text_3.png'),
      text_4: require('@/assets/div_2/text_4.png'),
      text_5: require('@/assets/div_2/text_5.png'),
      text_6: require('@/assets/div_2/text_6.png'),
      text_7: require('@/assets/div_2/text_7.png'),
      icon_2: require('@/assets/div_1/icon_2.png'),
      back: require('@/assets/div_2/back.png'),
      menu: require('@/assets/div_2/menu.png'),
    }
  },
  methods: {
    isHome() {
      return this.$route.name === '首页'
    }
  },
  created() {
  },
  mounted() {
    this.endTime = moment().format('YYYY')
  },
}
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}

img {
  display: block;
  height: auto;
}
</style>
