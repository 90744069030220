import Vue from "vue";
import VueRouter from 'vue-router'
import Home from "@/components/home.vue";
import Download from "@/components/download.vue";
import DownloadMobile from "@/components/download_mobile.vue";
import home_mobile from "@/components/home_mobile.vue";
import Pop_mobile from "@/components/pop_mobile.vue";
// 判断是不是移动端
let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
Vue.use(VueRouter)
let routes = [
  {
    path: '/',
    component: isMobile ? home_mobile : Home,
    name: '首页',
    icon: 'logo.png'
  },
  //移动端
  {
    path: '/pop',
    component: Pop_mobile,

  },
  {
    path: '/mobile_download',
    component: DownloadMobile,

  },
  //pc端
  {
    path: '/download',
    component: Download,

  }
]

let router = new VueRouter({
  routes,
  mode: 'history',
})
router.beforeEach((to, from, next) => {
  next();
});


export default router
